import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PaymentStatus } from '../../shared/types';

const PaymentComplete = () => {

  const location = useLocation();
  
  const searchParams = new URLSearchParams(location.search);
  const transactionGuid = searchParams.get('transactionGuid'); 

  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();

  const [iframeHeight, setIframeHeight] = useState('0px');

  useEffect(() => {

      const fetchSuccess = async () => {
          const response = await fetch(`transaction-result?transactionGuid=${transactionGuid}`);

          const data: PaymentStatus = await response.json();

          setPaymentStatus(data);

          setLoading(false);

          return data;
      };

      fetchSuccess();

      const handleResize = () => {
        const contentHeight = document.documentElement.scrollHeight;
        setIframeHeight(contentHeight + 'px');
      };

      // Initial height calculation
      handleResize();

      // Add resize event listener
      window.addEventListener('resize', handleResize);

      // Cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, [transactionGuid]);
    
    if (loading) {
      return <div>Checking the latest statement of the payment. Please wait...</div>;
    }

    return (
      <>
        <br />
        <div>
          {paymentStatus!.status === 'AUTHORISED' ? (
            <>
              Thank you for your payment; you will receive a payment confirmation via email. You can close this page now.
            </>
          ) : (
            <>
              Unfortunately, there has been a problem with your payment that has failed with status [
              {paymentStatus!.status}, {paymentStatus!.description}
              ]. Please advise the agent of this status.
            </>
          )}
        </div>
        <br />
      </>
    );
}

export default PaymentComplete;
